import { Box, Grid } from "@mui/material";
import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import Themes from "./Themes";

const TimeLine = (children) => {
  let { toggle } = children;
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/Themes");
  };
  return (
    <div
      style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem'}}
    >
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={12}>
          <p className="main-title" style={{ marginTop: "40px" }}>
            Registration involves two steps:
          </p>
          <ul className="main-titletheams" style={{  marginTop: "20px" }}>
            {" "}
            <li> Register yourself individually or as a team. </li>
          </ul>
          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              Idea Submission (from September 11<sup>th</sup>, 2023 to October 13<sup>th</sup>, 2023) is only possible
              after you have registered and formed a team. You need to submit a
              hack or an idea based on the given theme.{" "}
              <span style={{ fontWeight: "bold",color:"#052a58",cursor:"pointer" }} onClick={navigateToContacts}>
                Click here{" "}
              </span>
              for themes.{" "}
            </li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              Shortlisted teams will be announced on (November 10<sup>th</sup>, 2023) for the in-person hackathon event at IIT Bombay. Kindly note, teams need to organize their travel and stay as mentioned in the Rules section.

 
             
            </li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              In-person hackathon (November 27<sup>th</sup>, 2023) and finale will be held at{" "}
              <a  className="sub-title"  style={{marginLeft:"1px",color:"#052a58",cursor:"pointer"}}href="https://www.google.com/maps/dir/12.992741,77.7035726/iit+bombay+location/@16.0462758,72.6639112,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be7c7f189efc039:0x68fdcea4c5c5894e!2m2!1d72.9132679!2d19.1334302?entry=ttu"target="_blank">
                IIT Bombay.
              </a>{" "}
            </li>
          </ul>
          <p className="main-title">Important Dates:</p>

          <ul className="main-titletheams">
            {" "}
            <li> 11<sup>th</sup> September 2023 &ndash; Start of hackathon </li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li> 13<sup>th</sup> October 2023 &ndash; Last date to submit ideas</li>
          </ul>
          <ul className="main-titletheams">
            {" "}
            <li> 10<sup>th</sup> November 2023 &ndash; Shortlisted participants announcement</li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              27<sup>th</sup> November 2023 &ndash; In-person hackathon
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <p className="main-title" style={{ marginTop: "40px" }}>
            Timelines
          </p>
          <img
            style={{
              width: "90%",
              height: "80%",
              marginLeft: "30px",
              marginTop: "20px",
            }}
            src={`timelinebanner.png`}
            alt={""}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TimeLine;
