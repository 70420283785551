import React from "react";
const EligibilityList = [
  {
    Students: "Be a student currently pursuing UG or PG in any of the IITs",
 
  },

  {
    Students:
      "Include teams made up exclusively of IIT students. Non-IIT students are not allowed to be a part of the team",
   
  },
 
];
const Eligibility = (children) => {
  const renderHeaderEligibility = () => {
    let headerElement = ["Eligibility Criteria Entrants must:"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const renderBodyEligibility = () => {
    return (
      EligibilityList &&
      EligibilityList.map(({ Students, WorkingProfessionals }) => {
        return (
          <tr key={Students}>
            <td>{Students}</td>
          </tr>
        );
      })
    );
  };
  let { toggle } = children;
  return (
    <div style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem' }}>
      <p className="main-title">Eligibility Criteria</p>
      {/* <div style={{justifyContent:"center",display:"flex"}}> */}
      <table id="prizeMain" style={{ marginBottom: "50px" }}>
        <thead>
          <tr>{renderHeaderEligibility()}</tr>
        </thead>
        <tbody>{renderBodyEligibility()}</tbody>
      </table>
      {/* </div> */}
    </div>
  );
};

export default Eligibility;
