import { Box, Typography } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { motion, Animaepresence } from "framer-motion";
const mailis = [{
    "domain":"@iitkgp.ac.in",
    "Name":"IIT Kharagpur"
  },
  {
    "domain":"@iitb.ac.in",
    "Name":"IIT Bombay"
  },
  {
    "domain":"@iitm.ac.in",
    "Name":"IIT Madras"
  }
  ,
  {
    "domain":"@iitk.ac.in",
    "Name":"IIT Kanpur"
  }
  ,
  {
    "domain":"@iitd.ac.in",
    "Name":"IIT Delhi"
  }
  ,
  {
    "domain":"@iitg.ac.in",
    "Name":"IIT Guwahati"
  }
  ,
  {
    "domain":"@iitr.ac.in",
    "Name":"IIT Roorkee"
  }
  ,
  {
    "domain":"@iitrpr.ac.in",
    "Name":"IIT Ropar"
  }
  ,
  {
    "domain":"@iitbbs.ac.in",
    "Name":"IIT Bhubaneswar"
  }
  ,
  {
    "domain":"@iitgn.ac.in",
    "Name":"IIT Gandhinagar"
  }
  ,
  {
    "domain":"@iith.ac.in",
    "Name":"IIT Hyderabad"
  }
  ,
  {
    "domain":"@iitj.ac.in",
    "Name":"IIT Jodhpur"
  }
  ,
  {
    "domain":"@iitp.ac.in",
    "Name":"IIT Patna"
  }
  ,
  {
    "domain":"@iiti.ac.in",
    "Name":"IIT Indore"
  }
  ,
  {
    "domain":"@iitmd.ac.in",
    "Name":"IIT Mandi"
  }
  ,
  {
    "domain":"@iitbhu.ac.in",
    "Name":"IIT Varanasi"
  }
  ,
  {
    "domain":"@iitpkd.ac.in",
    "Name":"IIT Palakkad"
  }
  ,
  {
    "domain":"@iitt.ac.in",
    "Name":"IIT Tirupati"
  }
  ,
  {
    "domain":"@iitism.ac.in",
    "Name":"IIT Dhanbad"
  }
  ,
  {
    "domain":"@iitbh.ac.in",
    "Name":"IIT Bhilai"
  }
  ,
  {
    "domain":"@iitdh.ac.in",
    "Name":"IIT Dharwad"
  }
  ,
  {
    "domain":"@iitjmu.ac.in",
    "Name":"IIT Jammu"
  } ,
  {
    "domain":"@iitgoa.ac.in",
    "Name":"IIT Goa"
  }
   
  ];
function Login({ setLoginResponse }) {
    function validateDomain(email) {

        return mailis.some((val) => 
          email.includes(val.domain))
      }
  const [logindetails, setLoginDetails] = useState({
    solutioneceEmailId: "",
    iitEmailId: "",
    universityname: "",
  });
  const [check, setCheck] = useState({
    solutioneceEmailId: false,
    iitEmailId: false,
    universityname: false,
  });
 
  useEffect(() => {}, [logindetails]);
//   function validateDomain(email){ 
//     return mailis.some((val) => email.includes(val))
//   }  
  const updateHandleClickOpen = () => {
   console.log(validateDomain(logindetails.iitEmailId));
    if(validateDomain(logindetails.iitEmailId)){
        sessionStorage.setItem("logindetails", logindetails);
        setLoginResponse(logindetails);
    }
  };
  return (
    <Grid
      container
      display="flex"
      style={{
        backgroundColor: "blue",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Grid item xs={6} style={{ backgroundColor: "blue", overflow: "hidden" }}>
        <img
          width="100%"
          objectFit="fit"
          style={{ overflow: "hidden", objectFit: "fill", height: "100vh" }}
          src={`background-realistic.avif`}
          alt={"manoj"}
          loading="lazy"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ backgroundColor: "white" }}>
        {/* <img
          width="100%"
          objectFit="fit"
          style={{ overflow: "hidden", objectFit: "fill", height: "100vh",position:"absolute" }}
          src={`abstract.avif`}
          alt={"manoj"}
          loading="lazy"
        /> */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={8}
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                textAlign: "start",
                zIndex: 1,
              }}
            >
              <Typography
                fontSize={30}
                fontWeight="600"
                style={{
                  color: "#052a58",
                  fontFamily: "Arial, Verdana, sans-serif",
                  verticalAlign: "middle",
                }}
              >
                Sign Up
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                marginTop: "5px",
                marginBottom: "25px",
                textAlign: "start",
                zIndex: 1,
              }}
            >
              <Typography
                color="#46535e"
                fontSize={12}
                fontWeight="300"
                style={{
                  fontFamily: "Arial, Verdana, sans-serif",
                }}
              >
                To Join the conference, Please fill out the,
                {"\n"}
                registration form
                {/* {`To Join the conference, Please fill out the,\n registration form`} */}
              </Typography>
            </Grid>
            <Grid
              style={{ marginTop: "5px", marginBottom: "20px" }}
              item
              xs={8}
              md={8}
            >
              <TextField
                required
                id="outlined-required"
                label="Solutionec Email"
                size="small"
                inputProps={{
                  style: {
                    fontFamily: "nunito",
                    color: "rgb(0, 7, 61)",
                    borderColor: "3px solid red",
                  },
                }}
                fullWidth
                variant="outlined"
                value={logindetails.solutioneceEmailId}
                error={check.solutioneceEmailId}
                onChange={(e) => {
                  if (check.solutioneceEmailId) {
                    check.solutioneceEmailId = false;
                    setCheck({ ...check });
                  }

                  if (check.solutioneceEmailId) {
                    check.solutioneceEmailId = false;
                    setCheck({ ...check });
                  }
                  let detaill = logindetails;
                  detaill.solutioneceEmailId = e.target.value;
                  setLoginDetails({ ...detaill });
                
                }}
              />
            </Grid>
            <Grid
              style={{ marginTop: "5px", marginBottom: "20px" }}
              item
              xs={8}
              md={8}
            >
              <TextField
                required
                id="outlined-required"
                label="University Email"
                size="small"
                inputProps={{
                  style: {
                    fontFamily: "nunito",
                    color: "rgb(0, 7, 61)",
                    borderColor: "3px solid red",
                  },
                }}
                fullWidth
                variant="outlined"
                value={logindetails.iitEmailId}
                error={check.email}
                onChange={(e) => {
                  if (check.iitEmailId) {
                    check.iitEmailId = false;
                    setCheck({ ...check });
                  }

                  if (check.iitEmailId) {
                    check.iitEmailId = false;
                    setCheck({ ...check });
                  }
                  let detaill = logindetails;
                  detaill.iitEmailId = e.target.value;
                  setLoginDetails({ ...detaill });
                  mailis.some((val) => {
                    if(e.target.value.includes(val.domain)){
                      let detaill = logindetails;
                      detaill.universityname = val.Name;
                      setLoginDetails({ ...detaill });
                    }
                   
                  })
                }}
              />
            </Grid>
            <Grid
              style={{ marginTop: "5px", marginBottom: "20px" }}
              item
              xs={8}
              md={8}
            >
              <TextField
                required
                id="outlined-required"
                label="University Name"
                size="small"
                disabled={true}
                inputProps={{
                  style: {
                    fontFamily: "nunito",
                    color: "rgb(0, 7, 61)",
                    borderColor: "3px solid red",
                  },
                }}
                fullWidth
                variant="outlined"
                value={logindetails.universityname}
                error={check.universityname}
                onChange={(e) => {
                  if (check.universityname) {
                    check.universityname = false;
                    setCheck({ ...check });
                  }

                  if (check.universityname) {
                    check.universityname = false;
                    setCheck({ ...check });
                  }
                  let detaill = logindetails;
                  detaill.universityname = e.target.value;
                  setLoginDetails({ ...detaill });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <motion.button
                textAlign="center"
                style={{
                  fontFamily: "Arial, Verdana, sans-serif",
                  justifyContent: "center",
                  // background: "rgb(102, 108, 116)",
                  background: "#052a58",
                  textAlign: "center",
                  justifyItems: "center",
                  color: "white",
                  marginTop: "10px",
                  width: "50%",
                  borderRadius: 5,
                  border: "none",
                  padding: 10,
                  // cursor: "not-allowed",
                }}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                variant="outlined"
                onClick={updateHandleClickOpen}
              >
                Login
              </motion.button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
