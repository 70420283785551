
export const env = process.env.REACT_APP_ENV || 'development';
// export const env = process.env.REACT_APP_ENV || 'production';

export const port =  process.env.REACT_APP_ENV || 7002;

const envConfig = {
  default: {},
  development: {
    api: `http://localhost:${port}`,
  },
  testing: {
    api: `http://44.213.168.106:${port}`,
  },
  production: {
    api: `https://breakthrough.solutionec.com:${port}`,
    
  }
};
export const appConfig = envConfig[env];
