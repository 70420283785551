import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function AnimatedModal(props) {
  const { open, message,setResponse } = props;
  const classes = useStyles();
  const handleClose = () => {
    setResponse("");
  };
  return (
    <div style={{ display: "flex",zIndex:10}}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={message===""? false:true}>
          <div style={{ width: "40%" ,justifyContent:"center",textAlign:"center",padding:"0px"}} className={classes.paper}>
            <div style={{justifyContent:"right",justifyItems:"right",textAlign:"right"}}>
            <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
            </div>
        
            <Box >
           
              <p
                style={{
                  justifyContent:"center",
                  justifyItems:"center",
                  marginTop:"3px",
                  marginBottom:"8px",
                  marginLeft:"15px",
                  marginRight:"15px"
                }}
              >
                {message}
              </p>
            
             
            </Box>
            
            
            <Box style={{  margin:"auto"}}
                    >
                <Button
                  style={{
                    justifyContent: "center",
                    background: " rgb(0, 7, 61)",
                    color: "white",
                    borderRadius: 5,
                    borderColor: " rgb(0, 7, 61)",
                    width: "65%",
                    textAlign:"center",
                    marginTop: "4%",
                    marginBottom:"10px"

                    // height: "20%",
                  }}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
