import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
const routes = [
  {
    path: "/",
    name: "About",
    icon: <FaHome />,
    subRoutes: [
      {
        path: "/Overview",
        name: "Overview",
        icon: <FaMoneyBill />,
      },
      {
        path: "/TimeLine",
        name: "Timelines",
        icon: <FaUser />,
      },
      {
        path: "/Prizes",
        name: "Prizes",
        icon: <MdMessage />,
      },
      {
        path: "/Eligibility",
        name: "Eligibility",
        icon: <MdMessage />,
      }
     
    ],
  },
  {
    path: "/Themes",
    name: "Themes",
    icon: <FaUser />,
  },
  
  {
    path: "/FAQs",
    name: "FAQs",
    icon: <BiAnalyse />,
  },
 
  {
    path: "/Rules",
    name: "Rules",
    icon: <BsCartCheck />,
  }
];

const SideBar = (children ) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [isfirst ,setisfirst] = useState(true)
  let {isOpen,setIsOpen,toggle}=children
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
  <div className="main-containersidebar">
    <div
      className={`sidebar `} 
    >
    
      <section className="routes">
        {routes.map((route, index) => {
          return (
            <NavLink
              to={route.path}
              key={index}
              // className="link"
              // activeClassName={splitLocation[1] === "" ? "active" : ""}
              style={{justifyContent:"center",justifyItems:"center"}}
              className={['/Overview','/Prizes','/Eligibility','/TimeLine'].includes(pathname)&&route.name==='About'?'link active':"link"}
              activeStyle={"active"}
              onClick={(e) => {   
                route.name === "About" ? setisfirst(true) :setisfirst(false) 
            }}
            >
              <AnimatePresence>
                 (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
     
                  >
                    
                    {route.name}
                  </motion.div>
                
                )
              </AnimatePresence>
            </NavLink>
          );
        })}
      </section>
   
    </div>
    {/* <main>{children}</main> */}
    {isfirst && (
 <SidebarMenu 
 setIsOpen={setIsOpen}
 route={routes[0]}
 showAnimation={showAnimation}
 isOpen={isOpen}
  
/>  
  )}        
  </div>
      
    
  );
};

export default SideBar;
